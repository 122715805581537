<template>
  <b-modal
    ref="old-imei-item-detail"
    id="old-imei-item-detail"
    title="Chi tiết sản phẩm"
    hide-footer
    size="xl"
    centered
    scrollable
  >
    <b-container>
      <b-row>
        <b-col md="3">
          <p class="text-dark font-size-h5 font-weight-bold">
            Thông tin hóa đơn
          </p>
          <b-form-group>
            <label>Loại hóa đơn</label>
            <b-form-input v-model="billTypeText" size="sm" :disabled="true">
            </b-form-input>
          </b-form-group>
          <b-form-group>
            <label>Kiểu</label>
            <b-form-input
              v-model="billModeText"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label>Thời gian tạo</label>
            <b-form-input
              v-model="billDetail.createdDateTime"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <p class="text-dark font-size-h5 font-weight-bold">
            Thông tin khách hàng
          </p>
          <b-form-group>
            <label>Họ và tên</label>
            <b-form-input
              v-model="billDetail.customerName"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label>Số điện thoại</label>
            <b-form-input
              v-model="billDetail.customerMobile"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <p class="text-dark font-size-h5 font-weight-bold">
            Giá bán
          </p>
          <b-form-group>
            <label>Tổng tiền</label>
            <b-form-input
              v-model="billDetail.money"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label>Tiền mặt</label>
            <b-form-input
              v-model="billDetail.cash"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label>Tiền chuyển khoản</label>
            <b-form-input
              v-model="billDetail.moneyTransfer"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <p class="text-dark font-size-h5 font-weight-bold">
            Nhân viên
          </p>
          <b-form-group>
            <label>Người tạo</label>
            <b-form-input
              v-model="billDetail.createdByName"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label>Nhân viên bán hàng</label>
            <b-form-input
              v-model="billDetail.saleName"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label>Nhân viên kỹ thuật</label>
            <b-form-input
              v-model="billDetail.technicalName"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          :items="billItems"
          :fields="billItemFields"
          fixed
          bordered
          hover
          :busy="onLoading"
          show-empty
          small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Đang tải dữ liệu ...</strong>
            </div>
          </template>
          <template #empty="">
            <div class="no-data">Không có dữ liệu</div>
          </template>
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(createdAt)="row">
            {{ formatDateTime(row.item.createdAt) }}
          </template>
          <template v-slot:cell(discount)="row">
            {{ convertPrice(row.item.discount) }}
          </template>
          <template v-slot:cell(price)="row">
            <p v-if="row.item.price" style="font-size: 12px; margin-bottom: 0">
              {{ convertPrice(row.item.price) }}
            </p>
            <span>---</span><br />
            <span style="white-space: normal"
              >Giảm giá: {{ convertPrice(row.item.discount) }}</span
            >
          </template>
          <template v-slot:cell(imei)="row">
            <div class="text-wrap">
              {{ row.item.imei.split(',').join('\n') }}
            </div>
          </template>
        </b-table>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            size="sm"
            @click="$bvModal.hide('old-imei-item-detail')"
          >
            Đóng
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import ApiService from '../../../core/services/api.service';
import { get } from 'lodash';
import moment from 'moment';
import { convertPrice } from '../../../utils/common';
import { BILL_NHANH_TYPE, BILL_NHANH_MODE } from '../../../utils/constants';

export default {
  props: ['itemId'],
  data() {
    return {
      billId: null,
      billDetail: {},
      billItems: [],
      billItemFields: [
        {
          key: 'index',
          label: 'STT',
          thStyle: { textAlign: 'center', width: '3%' },
          tdClass: 'text-center'
        },
        {
          key: 'idNhanh',
          label: 'ID',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'code',
          label: 'Mã sản phẩm',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'name',
          label: 'Tên sản phẩm',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '25%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '5%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'price',
          label: 'Giá',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'imei',
          label: 'IMEI'
        }
      ],
      onLoading: false,
      name: 'Thang'
    };
  },
  methods: {
    convertPrice,
    formatDateTime(input) {
      return moment(input).format('YYYY-MM-DD HH:mm:ss');
    },
    fetchBillDetail(billId) {
      if (!billId) {
        return;
      }

      this.onLoading = true;
      this.clearData();

      ApiService.get(`old-bill/${billId}`).then(response => {
        const { billItems, ...billDetail } = get(response, 'data.data');
        this.billItems = billItems;
        this.billDetail = billDetail;

        this.onLoading = false;
      });
    },
    clearData() {
      this.billDetail = {};
      this.billItems = [];
    }
  },
  watch: {
    $props: {
      handler() {
        this.billId = this.itemId;
        this.fetchBillDetail(this.itemId);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    billTypeText() {
      return BILL_NHANH_TYPE[this.billDetail.type];
    },
    billModeText() {
        return BILL_NHANH_MODE[this.billDetail.mode];
    }
  }
};
</script>

<style scoped>
.no-data {
  text-align: center;
  font-size: 13px;
}
</style>
